import React, { useState } from "react";
import IMAGES from "./images/gallery.json";

export default function Galeria() {
  const [selectedImg, setSelectedImg] = useState(IMAGES[0]);

  var clicked = 0;
  var mobile = window.matchMedia("(max-width: 768px)")

  function openFullImg(){
    clicked++;

    if (clicked%2===0){
      document.getElementById("selected").style.width = "70%";
      document.getElementById("selected").style.transition = "1s ease-out";
    } else if (clicked%2!==0 && mobile.matches){
      document.getElementById("selected").style.width = "90%";
      document.getElementById("selected").style.transition = "1s ease-out";
    } else {
      document.getElementById("selected").style.width = "100%";
      document.getElementById("selected").style.transition = "1s ease-out";
    }

  }
  return (
    <section>
      <div className="section1" id="galeria">
        <h1>Galeria</h1>
      </div>
      <div className="galeria">
        <div className="container">
          <img
            src={selectedImg.image}
            alt="selected"
            className="selected"
            id="selected"
            onClick={() => openFullImg()}
          />

          <div className="imgContainer">
            {IMAGES.map((img) => (
              <img
                style={{
                  outlineColor: selectedImg === img ? "green" : "",
                  transform: selectedImg === img ? "scale(0.8)" : "",
                  borderRadius: selectedImg === img ? "20px" : ""
                }}
                key={img.id}
                src={img.image}
                alt="agromarket"
                onClick={() => setSelectedImg(img)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
