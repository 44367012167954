import React from "react";
import allegro from "./images/allegro.webp";

export default function Online() {
  return (
    <article>
      <h2 className="verdana center_horizontal">O<span>raz setki innych artykułów m.in. wędzarni</span>e!</h2>

      <div className="sklep">
        <a href="https://allegro.pl/uzytkownik/agromarket89">
          <img src={allegro} alt="sklep allegro" />
        </a>
        <p>Część naszych produktów jest możliwa do kupienia online!</p>
      </div>
    </article>
  );
}
