import React from "react";
import image_naszaoferta from "./images/rolnik1.webp";
import image2 from "./images/rolnik2.webp";
import image3 from "./images/rolnik3.webp";

export default function Naszaoferta() {
  return (
    <section>
      <div className="section1" id="oferta">
        <h1>Nasza oferta</h1>
      </div>
      <div className="bordersOferta">
        <div className="borderOferta1"></div>
        <div className="borderOferta2"></div>
        </div>
      <div className="artykuly">
        <div className="artykuly_rolnicze">
          <img className="image_naszaoferta" src={image_naszaoferta} alt="artykuły rolnicze" />
          <article className="col1">
          <h2 className="center_horizontal">Artykuły rolnicze</h2>

          <ul>
            <li>Nawozy rolnicze</li>
            <li>Środki ochrony roślin</li>
            <li>Nasiona</li>
            <li>Pasza dla zwierząt</li>
            <li>Preparaty do zwalczania owadów</li>
            <li>Preparaty do zwalczania gryzoni</li>
            <li>Części do maszyn rolniczych</li>
          </ul>
          </article>
        </div>

        <div className="Artykuły_ogrodnicze">
          <img className="image_naszaoferta" src={image2} alt="artykuły ogrodnicze" />
          <article className="col1">
          <h2 className="center_horizontal">Artykuły ogrodnicze</h2>
          <ul>
            <li>Nawozy ogrodnicze</li>
            <li>Torfy i substraty</li>
            <li>Folie, siatki i tkaniny</li>
            <li>Węże, nawodnienia i opryskiwacze</li>
            <li>Doniczki</li>
            <li>Narzędzia ogrodnicze</li>
            <li>Ozdoby na zewnątrz</li>
          </ul>
          </article>
        </div>

        <div className="Artykuły_przemysłowe">
          <img className="image_naszaoferta" src={image3} alt="artykuły przemysłowe" />
          <article className="col1">
          <h2 className="center_horizontal">Artykuły przemysłowe</h2>
          <ul>
            <li>Ubrania robocze</li>
            <li>Kartony, skrzynki plastikowe</li>
            <li>Skrzynki drewniane</li>
            <li>Worki raszlowe i inne opakowania</li>
            <li>Klucze, śrubokręty, piły</li>
            <li>Środki do dezynfekcji</li>
            <li>Baterie, kleje, latarki</li>
            <li> Przedłużacze</li>
            <li>Łopaty, grabie, miotły</li>
          </ul>
        </article>
        </div>
      </div>
    </section>
  );
}
