import React from 'react';

import Adres from './components/Adres';
import Agromarket from './components/Agro';
import Galeria from './components/Galeria';
import Naszaoferta from './components/Naszaoferta';
import Navbar from './components/Navbar';
import Onas from './components/Onas';
import Online from './components/Online';
import './components/styles/adres.css';
import './components/styles/agro.css';
import './components/styles/galeria.css';
import './components/styles/naszaoferta.css';
import './components/styles/navbar.css';
import './components/styles/onas.css';
import './components/styles/online.css';
import './components/styles/style.css';

function App() {
  return (
    <>
      <Navbar />
      <Agromarket />
        <main>
          <Onas />
          <Naszaoferta />
          <Online />
          <Adres />
          <Galeria />
        </main>
    </>
  );
}

export default App;
