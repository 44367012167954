import React from "react";
import logo from "./images/logo.webp";
import home from "./images/home.png";
import allegro from "./images/allegro.webp";
import contact from "./images/contact.png";
import adres from "./images/location.png";
import gallery from "./images/gallery.png";
import './styles/navbar.css';

export default function Navbar() {
  
  return (
    <div>
      <nav>
        <div className="logo">
          <img className="logo mobileIcon" src={logo} alt="logo" />
        </div>

        <ul className="ul">
          <li>
            <a href="#onas">
              <img className="icon" src={home} alt="home" />
              <p>
                <strong>Home</strong>
              </p>
            </a>
          </li>
          <li>
            <a href="https://allegro.pl/uzytkownik/agromarket89">
              <img className="icon mobileIcon" src={allegro} alt="shop" />
              <p>
                <strong>Sklep</strong>
              </p>
            </a>
          </li>
          <li>
            <a href="#kontakt">
              <img className="icon" src={contact} alt="contact" />
              <p>
                <strong>Kontakt</strong>
              </p>
            </a>
          </li>
          <li>
            <a href="#adres">
              <img className="icon" src={adres} alt="adres" />
              <p>
                <strong>Adres</strong>
              </p>
            </a>
          </li>
          <li>
            <a href="#galeria">
              <img className="icon" src={gallery} alt="gallery" />
              <p>
                <strong>Galeria</strong>
              </p>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
