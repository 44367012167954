import React from "react";
import headervideo from "./images/headervideo.mp4";

export default function Agromarket() {
  return (
    <header className="video">
      <video autoPlay muted id="video">
        <source src={headervideo} type="video/mp4" />
      </video>
        <a href="#onas"><b>Wejdź na stronę</b></a>
    </header>
  );
}
