import React from "react";

export default function Adres() {
  return (
    <section>
      <div className="kontakt" id="kontakt">
        <div className="border-mid"></div>
        <div className="telefon">
          <h2>
            T<span>ELEFO</span>N
          </h2>
          <p>661 308 524</p>
        </div>
        <div className="praca">
          <h2>
            G<span class="desktop">ODZINY PRAC</span>Y
          </h2>
          <div className="godziny">
            <div className="dni">
              <p>pon - pt </p>
              <p>sobota </p>
              <p id="green">niedziela</p> <br />
            </div>
            <div className="czas">
              <p>8:00 - 16:00</p>
              <p>8:00 - 14:00</p>
              <p>Zamknięte</p>
            </div>
          </div>
        </div>
      </div>

      <div className="adres background" id="adres">
        <h1>Adres</h1>
        <div className="margin1">
          <p className="grey">
            <strong>PRZEMYSŁOWA 1</strong>
          </p>
          <p className="grey">
            <strong>42-445 SZCZEKOCINY</strong>
          </p>
        </div>
        {/* --------------MAPA------------- */}
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              title="map"
              width="900"
              height="600"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=agromarket%20szczekociny&t=k&z=13&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}
