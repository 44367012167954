import React from "react";
import zdjecie1 from "./images/zdjecie1.png";
import zdjecie2 from "./images/zdjecie2.png";

export default function Onas() {
  return (
    <section id="onas">
      <div className="section1">
        <h1> O nas </h1>
      </div>
      <div className="taczka">
        <p>
          Jesteśmy Polską firmą zajmującą się
          <br /> sprzedażą hurtową oraz detaliczną <br />
          artykułów ogrodniczych, rolniczych oraz <br />
          przemysłowych.
        </p>
      </div>

      <div className="section2">
        <img src={zdjecie1} alt="zdjecie1" />
        <p>
          Konkurencyjne ceny, fachowe doradztwo oraz profesjonalna obsługa
          umożliwiają nam stałe poszerzanie grona naszych klientów.
        </p>
      </div>

      <div className="section2">
        <p>
          Jakiś krótki text do dodania o Waszej firmie lub o tym co robicie,
          zachęcający do kupienia Waszych artykułów
        </p>
        <img className="zdjecie" src={zdjecie2} alt="zdjecie2" />
      </div>
    </section>
  );
}
